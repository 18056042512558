import TabsMenu from '@components/screens/TabsMenu';
import { Box, Stack } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useLocation } from 'react-router-dom';
import { Navbar } from './ui/mailberry/Navbar';

const Layout = ({ children, scroll = true }: { children: React.ReactNode; scroll?: boolean; }) => {
  let location = useLocation();
  const isMobileView = useMediaQuery('(max-width: 48em)');

  const showBackArrow = location.pathname !== '/' && location.pathname !== '/demo';

  return (
    <Stack
      w='100%'
      maw={'1000px'}
      gap={0}
      style={{ height: 'calc(100dvh - (env(safe-area-inset-top) + env(safe-area-inset-bottom)) )' }}
    >
      <Navbar showBackArrow={showBackArrow} isMobileView={isMobileView} />
      <Box style={{ flexGrow: 2, grow: 2, borderBottom: '1px solid #E5E7EB', alignContent: 'center', overflowY: scroll ? 'scroll' : 'hidden' }}>
        {children}
      </Box>
      {isMobileView && location.pathname !== '/chat' && <TabsMenu />}
    </Stack>
  );
};

export default Layout;
